export const sizeMap = Object.freeze({
  small: {
    height: 14,
    width: 24,
    borderWidth: 1
  },
  medium: {
    height: 24,
    width: 41,
    borderWidth: 1
  }
});

export type Size = keyof typeof sizeMap;
