import { ResponsiveProp } from "../responsiveProps";
import {
  forDesktopUp,
  forPhoneOnly,
  forTabletOnly,
  forTabletUp
} from "./mediaQueries";

type CssValue = string | number | typeof undefined;

export function responsiveToCss(
  propertyName: string,
  responsiveValue?: ResponsiveProp<CssValue>,
  important?: boolean
) {
  if (!Array.isArray(responsiveValue)) {
    return cssValue(propertyName, responsiveValue, important);
  }

  if (responsiveValue.length === 2) {
    const [mobileDimension, tabletAndUpDimension] = responsiveValue;

    return (
      forPhoneOnly(cssValue(propertyName, mobileDimension, important)) +
      forTabletUp(cssValue(propertyName, tabletAndUpDimension, important))
    );
  }

  if (responsiveValue.length === 3) {
    const [
      mobileDimension,
      tabletOnlyDimension,
      desktopAndUpDimension
    ] = responsiveValue;

    return (
      forPhoneOnly(cssValue(propertyName, mobileDimension, important)) +
      forTabletOnly(cssValue(propertyName, tabletOnlyDimension, important)) +
      forDesktopUp(cssValue(propertyName, desktopAndUpDimension, important))
    );
  }

  throw new Error(
    `Received unknown responsiveValue: ${JSON.stringify(responsiveValue)}`
  );
}

function cssValue(propertyName: string, value?: CssValue, important?: boolean) {
  return isValidValue(value)
    ? `${propertyName}: ${value}${important ? " !important" : ""};`
    : "";
}

function isValidValue(value?: CssValue): value is CssValue {
  if (value || value === 0) {
    return true;
  }

  return false;
}
